import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Text } from './text';

const TextCockpit = ({ id }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitText {
            edges {
              node {
                cockpitId
                title {
                  value
                }
                text {
                  value {
                    internal {
                      content
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitText }) => {
        const current = allCockpitText.edges.find(
          ({ node }) => node.cockpitId === id
        ).node;

        return <Text text={current.text.value.internal.content} />;
      }}
    />
  );
};

export { TextCockpit };
