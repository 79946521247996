import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import style from './modal.module.scss';

const Modal = ({ children, open, className, changeOpen, image }) => {
  if (image) {
    return (
      <>
        {open && (
          <div
            className={style.wrapper}
            onClick={() => {
              if (typeof changeOpen === 'function') {
                changeOpen();
              }
            }}
          >
            <div
              className={`${style.content} ${style.image} ${
                Boolean(className) ? ` ${className}` : ''
              }`}
            >
              <button
                onClick={() => {
                  if (typeof changeOpen === 'function') {
                    changeOpen();
                  }
                }}
                className={style.close}
              />
              {children}
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {open && (
        <div
          className={style.wrapper}
          onClick={() => {
            if (typeof changeOpen === 'function') {
              changeOpen();
            }
          }}
        >
          <div
            className={`${style.content}${
              Boolean(className) ? ` ${className}` : ''
            }`}
          >
            <button
              onClick={() => {
                if (typeof changeOpen === 'function') {
                  changeOpen();
                }
              }}
              className={style.close}
            />
            <Scrollbars>
              <div className={style.inner} onClick={e => e.stopPropagation()}>
                {children}
              </div>
            </Scrollbars>
          </div>
        </div>
      )}
    </>
  );
};

export { Modal };
