import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal } from '../modal';

import style from './news.module.scss';
import { ResizeableImage } from '../image/resizeable';

const NewsItem = props => {
  const [open, setOpen] = useState(false);
  const [likes, setLikes] = useState();
  const changeOpen = () => setOpen(!open);
  const date = new Date(props.created);

  useEffect(() => {
    const call = async () => {
      const likes = await fetch(
        `https://content.k9-kommando.de/api/public/likes/show?form=newslikes&id=${props.id}`
      ).then(data => data.json());
      setLikes(likes);
    };

    call();
  }, [props.id]);

  const onClick = like => {
    const likeStr =
      Boolean(likes) && likes.self !== like
        ? like
          ? '&like=true'
          : '&like=false'
        : '';
    fetch(
      `https://content.k9-kommando.de/api/public/likes/add?form=newslikes&id=${props.id}${likeStr}`
    )
      .then(data => data.json())
      .then(setLikes);
  };

  return (
    <div
      className={`${style.item} ${
        Boolean(props.text) || Boolean(props.source) ? style.hasFooter : ''
      }`}
      key={`news-${props.id}`}
    >
      <div className={style.header}>
        <div className="date">
          <small>
            {date.toLocaleString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            Uhr
          </small>
        </div>
        <div
          className={`${style.like}${
            Boolean(likes) && likes.self !== null
              ? likes.self
                ? ` ${style.liked}`
                : ` ${style.disliked}`
              : ''
          }`}
        >
          <button
            onClick={() => onClick(true)}
            className={`${style.button} ${style.buttonLike}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792">
              <path
                fill="currentColor"
                d="M1409.085 701.83v-128q0-26-19-45t-45-19h-502l189-189q19-19 19-45t-19-45l-91-91q-18-18-45-18t-45 18l-362 362-91 91q-18 18-18 45t18 45l91 91 362 362q18 18 45 18t45-18l91-91q18-18 18-45t-18-45l-189-189h502q26 0 45-19t19-45zm256-64q0 209-103 385.5t-279.5 279.5q-176.5 103-385.5 103t-385.5-103q-176.5-103-279.5-279.5t-103-385.5q0-209 103-385.5t279.5-279.5q176.5-103 385.5-103t385.5 103q176.5 103 279.5 279.5t103 385.5z"
              />
            </svg>
            <small>
              {Boolean(likes) && Boolean(likes.likes)
                ? ` ${likes.likes}`
                : ' 0'}
            </small>
          </button>
          <button
            onClick={() => onClick(false)}
            className={`${style.button} ${style.buttonDislike}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792">
              <path
                fill="currentColor"
                d="M1409.085 701.83v-128q0-26-19-45t-45-19h-502l189-189q19-19 19-45t-19-45l-91-91q-18-18-45-18t-45 18l-362 362-91 91q-18 18-18 45t18 45l91 91 362 362q18 18 45 18t45-18l91-91q18-18 18-45t-18-45l-189-189h502q26 0 45-19t19-45zm256-64q0 209-103 385.5t-279.5 279.5q-176.5 103-385.5 103t-385.5-103q-176.5-103-279.5-279.5t-103-385.5q0-209 103-385.5t279.5-279.5q176.5-103 385.5-103t385.5 103q176.5 103 279.5 279.5t103 385.5z"
              />
            </svg>
            <small>
              {Boolean(likes) && Boolean(likes.dislikes)
                ? ` ${likes.dislikes}`
                : ' 0'}
            </small>
          </button>
        </div>
      </div>
      <h3>{props.title}</h3>
      <h4>{props.subtitle}</h4>
      {props.image && (
        <ResizeableImage className={style.image} {...props.image} />
      )}
      <ReactMarkdown source={props.teaser} className={style.teaser} />
      {(Boolean(props.text) || Boolean(props.source)) && (
        <div className={style.footer}>
          {Boolean(props.text) && (
            <>
              <button onClick={changeOpen} className="btn">
                Details
              </button>
            </>
          )}
          {Boolean(props.source) && (
            <div className={style.source}>
              <a href={props.source} target="_blank" rel="noopener noreferrer">
                {
                  props.source
                    .replace('http://', '')
                    .replace('https://', '')
                    .split(/[/?#]/)[0]
                }
              </a>
            </div>
          )}
        </div>
      )}

      {Boolean(props.text) && (
        <Modal open={open} changeOpen={changeOpen}>
          <ReactMarkdown source={props.text} className={`newsText`} />
        </Modal>
      )}
    </div>
  );
};

export { NewsItem };
