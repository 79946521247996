import React, { useState, useEffect } from 'react';
import style from './header.module.scss';
import { Link, StaticQuery, graphql } from 'gatsby';

const Header = props => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (!refresh) setRefresh(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header style={{ position: 'relative' }}>
      <StaticQuery
        query={graphql`
          query {
            allCockpitHeader(
              sort: { fields: cockpitCreated, order: DESC }
              filter: { published: { value: { eq: true } } }
            ) {
              edges {
                node {
                  image {
                    value {
                      childImageSharp {
                        fixed(quality: 85, width: 1920) {
                          src
                        }
                      }
                    }
                  }
                  title {
                    value
                  }
                  copyright {
                    value
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const allImages = data.allCockpitHeader.edges;
          const currentImage =
            allImages[Math.floor(Math.random() * allImages.length)].node;

          return (
            <>
              <div
                className={`${style.header}${
                  props.small ? ` ${style.small}` : ''
                }`}
              />
              {refresh && (
                <div
                  className={`${style.headerBg}${
                    props.small ? ` ${style.small}` : ''
                  }`}
                  style={{
                    backgroundImage: `url(${currentImage.image.value.childImageSharp.fixed.src})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                />
              )}
              <Link to="/">
                <div className={style.logo}>
                  <img
                    src={require('../images/k9-kommando.png')}
                    alt="K9 Kommando"
                  />
                </div>
                <div className={style.headerText}>
                  <span className={style.headline}>K9 Kommando</span>
                  <span className={style.subline}>multigaming since 2005</span>
                </div>
              </Link>
              <div className={style.clip}>
                <div className={style.copyright}>
                  Bildquelle: &copy; {currentImage.copyright.value}
                </div>
              </div>
            </>
          );
        }}
      />
    </header>
  );
};

export default Header;
