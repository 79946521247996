import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MemberList } from './list';

const MemberCockpit = ({ id }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitMemberList {
            edges {
              node {
                title {
                  value
                }
                shuffle {
                  value
                }
                number {
                  value
                }
                respectRank {
                  value
                }
                cockpitId
              }
            }
          }
          allCockpitMember(filter: { published: { value: { eq: true } } }) {
            edges {
              node {
                id
                name {
                  value
                }
                rank {
                  value {
                    name {
                      value
                    }
                  }
                }
                image {
                  value {
                    childImageSharp {
                      resize(
                        width: 304
                        height: 171
                        fit: COVER
                        cropFocus: CENTER
                        quality: 100
                      ) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
          allCockpitRanks {
            edges {
              node {
                name {
                  value
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitMemberList, allCockpitMember, allCockpitRanks }) => {
        const current = allCockpitMemberList.edges.find(
          ({ node }) => node.cockpitId === id
        ).node;

        const members = [...allCockpitMember.edges].map(({ node }) => {
          return {
            id: node.id,
            name: node.name.value,
            rank: node.rank.value.name.value,
            image: Boolean(node.image)
              ? node.image.value.childImageSharp.resize.src
              : undefined,
          };
        });

        const ranks = [...allCockpitRanks.edges].map(
          ({ node }) => node.name.value
        );

        return (
          <>
            {Boolean(current.title) && <h2>{current.title.value}</h2>}
            <MemberList
              number={
                Boolean(current.number)
                  ? parseInt(current.number.value)
                  : undefined
              }
              members={members}
              ranks={ranks}
              respectRank={current.respectRank.value}
              shuffle={current.shuffle.value}
            />
          </>
        );
      }}
    />
  );
};

export { MemberCockpit };
