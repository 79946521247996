import React, { useState } from 'react';
import { Modal } from '../modal';

const ResizeableImage = ({ src, srcSet, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <img
        src={src}
        srcSet={srcSet}
        alt={src}
        className={className ? className : null}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <Modal open={open} changeOpen={() => setOpen(!open)} image={true}>
          <img
            src={src}
            alt={src}
            srcSet={srcSet}
            className={className ? className : null}
          />
        </Modal>
      )}
    </>
  );
};

export { ResizeableImage };
