import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from './image';
import { Slider } from '../slider';

const ImageCockpit = ({ id, className }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitImages {
            edges {
              node {
                cockpitId
                images {
                  value {
                    id
                    childImageSharp {
                      fluid(
                        maxWidth: 921
                        maxHeight: 518
                        fit: COVER
                        cropFocus: CENTER
                        srcSetBreakpoints: [320, 640, 861, 921]
                        quality: 100
                      ) {
                        srcSet
                        src
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitImages }) => {
        const current = allCockpitImages.edges.find(
          ({ node }) => node.cockpitId === id
        ).node;

        if (current.images.value.length === 1) {
          const { childImageSharp, caption } = current.images.value[0];

          return (
            <Image
              className={className}
              src={childImageSharp.fluid.src}
              srcSet={childImageSharp.fluid.srcSet}
              caption={caption}
              title={childImageSharp.fluid.originalName}
            />
          );
        }

        return (
          <Slider className={className}>
            {current.images.value.map(
              ({ id, childImageSharp, title, caption }) => (
                <Image
                  key={id}
                  src={childImageSharp.fluid.src}
                  srcSet={childImageSharp.fluid.srcSet}
                  caption={caption}
                  title={childImageSharp.fluid.originalName}
                />
              )
            )}
          </Slider>
        );
      }}
    />
  );
};

export { ImageCockpit };
