import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Textimage } from './textimage';
import ReactMarkdown from 'react-markdown';

const TextimageCockpit = ({ id }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitTextimage {
            edges {
              node {
                cockpitId
                image {
                  value {
                    childImageSharp {
                      fluid(
                        fit: COVER
                        maxWidth: 300
                        quality: 100
                        cropFocus: CENTER
                      ) {
                        src
                        originalName
                      }
                    }
                  }
                }
                text {
                  value {
                    internal {
                      content
                    }
                  }
                }
                imagePosition {
                  value
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitTextimage }) => {
        const current = allCockpitTextimage.edges.find(
          ({ node }) => node.cockpitId === id
        ).node;

        const { childImageSharp } = current.image.value;

        return (
          <Textimage
            image={childImageSharp.fluid.src}
            imagePosition={
              current.imagePosition.value === 'rechts' ? 'right' : 'left'
            }
            imageAlt={childImageSharp.fluid.originalName}
          >
            <ReactMarkdown>{current.text.value.internal.content}</ReactMarkdown>
          </Textimage>
        );
      }}
    />
  );
};

export { TextimageCockpit };
