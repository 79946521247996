import React from 'react';
import { MemberCard } from './card';

import {
  shuffle as arrShuffle,
  sortByField as arrSortByField,
} from '../../utils/array';

import style from './member.module.scss';
import { StaticQuery, graphql, Link } from 'gatsby';

const sortByRank = (array, order) => {
  let returnArr = [];

  order.forEach(item => {
    returnArr = [
      ...returnArr,
      ...array.filter(({ rank }) => rank.toLowerCase() === item.toLowerCase()),
    ];
  });

  return returnArr;
};

class MemberList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      width: undefined,
    };
  }

  handleResize = () => {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  };

  componentDidMount() {
    this.setState({ width: window.innerWidth, refresh: true });

    this.resizeListener = window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { shuffle, members, number, ranks, respectRank = true } = this.props;
    const { width, refresh } = this.state;

    let showMembers = [...members];

    if (Boolean(shuffle)) {
      arrShuffle(showMembers);
    } else {
      arrSortByField(showMembers, 'name');

      if (Boolean(respectRank)) {
        showMembers = sortByRank(showMembers, ranks);
      }
    }

    if (Boolean(number) && number > 0) {
      let showItems = number;

      if (width < 651) {
        showItems = number - (number % 2);
      }

      showMembers = showMembers.splice(0, showItems);
    }

    return (
      <StaticQuery
        query={graphql`
          {
            allSitePage {
              edges {
                node {
                  path
                }
              }
            }
          }
        `}
        render={({ allSitePage }) => {
          const paths = allSitePage.edges.map(({ node }) => node.path);

          return (
            <>
              {refresh && (
                <>
                  <div className={style.memberList}>
                    {refresh || typeof items === 'undefined'
                      ? showMembers.map(item => (
                          <MemberCard key={item.id} member={item} />
                        ))
                      : ''}
                  </div>
                  {paths.filter(path => path === '/member').length > 0 &&
                    Boolean(this.props.number) && (
                      <Link to="/member" className={`${style.allLink} btn`}>
                        Alle Member
                      </Link>
                    )}
                </>
              )}
            </>
          );
        }}
      />
    );
  }
}

export { MemberList };
