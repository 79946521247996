import React from 'react';
import { NewsItem } from './item';

import style from './news.module.scss';

class NewsList extends React.Component {
  render = () => {
    return (
      <>
        {Boolean(this.props.title) && <h2>{this.props.title}</h2>}
        <div className={style.list}>
          {this.props.items.map(item => (
            <NewsItem {...item} key={`news-${item.id}`} />
          ))}
        </div>
      </>
    );
  };
}

export { NewsList };
