const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const sortByField = (array, field, direction = 'asc') => {
  array.sort((a, b) => {
    if (a[field].toLowerCase() < b[field].toLowerCase()) {
      return direction.toLowerCase() === 'asc' ? -1 : 1;
    }
    if (a[field].toLowerCase() > b[field].toLowerCase()) {
      return direction.toLowerCase() === 'asc' ? 1 : -1;
    }
    return 0;
  }, array);
};

export { shuffle, sortByField };
