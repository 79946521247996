import React, { Component } from 'react';

import './slider.scss';

let TinySlider = undefined;

const settings = {
  controlsPosition: 'bottom',
  navPosition: 'bottom',
  mouseDrag: true,
  controlsText: [
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 1024"><defs/><path d="M576 64v896q0 26-19 45t-45 19-45-19L19 557Q0 538 0 512t19-45L467 19q19-19 45-19t45 19 19 45z"/></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 1024"><defs/><path d="M576 512q0 26-19 45l-448 448q-19 19-45 19t-45-19-19-45V64q0-26 19-45T64 0t45 19l448 448q19 19 19 45z"/></svg>',
  ],
};

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    TinySlider = require('tiny-slider-react').default;
    this.setState({ loaded: true });
  }

  render() {
    return (
      <>
        {Boolean(TinySlider) && (
          <TinySlider className={this.props.className} settings={settings}>
            {this.props.children}
          </TinySlider>
        )}
      </>
    );
  }
}

export { Slider };
