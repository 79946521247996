import React from 'react';
import { MemberCockpit } from '../components/member/cockpit';
import { TextCockpit } from '../components/text/cockpit';
import { ImageCockpit } from '../components/image/cockpit';
import { TextimageCockpit } from '../components/textimage/cockpit';
import { NewsCockpit } from '../components/news/cockpit';

const ElementSwitch = ({ elements = [] }) =>
  elements.map(element => {
    let renderElement = undefined;

    switch (element.link) {
      case 'memberList':
        renderElement = <MemberCockpit id={element._id} />;
        break;
      case 'newslist':
        renderElement = <NewsCockpit id={element._id} />;
        break;
      case 'text':
        renderElement = <TextCockpit id={element._id} />;
        break;
      case 'images':
        renderElement = <ImageCockpit id={element._id} />;
        break;
      case 'textimage':
        renderElement = <TextimageCockpit id={element._id} />;
        break;

      default:
        renderElement = <div>undefined {element.link}</div>;
    }

    return <section key={element._id}>{renderElement}</section>;
  });

export { ElementSwitch };
