import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { NewsList } from './list';

const NewsCockpit = ({ id }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allCockpitNewslist {
            edges {
              node {
                number {
                  value
                }
                title {
                  value
                }
                cockpitId
              }
            }
          }
          allCockpitNews(
            sort: { fields: cockpitCreated, order: DESC }
            filter: { published: { value: { eq: true } } }
          ) {
            edges {
              node {
                cockpitCreated
                cockpitId
                cockpitBy
                publishDate {
                  value
                }
                publishTime {
                  value
                }
                source {
                  value
                }
                title {
                  value
                }
                subtitle {
                  value
                }
                teaser {
                  value {
                    internal {
                      content
                    }
                  }
                }
                text {
                  value {
                    internal {
                      content
                    }
                  }
                }
                image {
                  value {
                    childImageSharp {
                      fluid(
                        maxWidth: 446
                        fit: COVER
                        maxHeight: 251
                        quality: 80
                        sizes: ""
                      ) {
                        srcSet
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allCockpitNews, allCockpitNewslist }) => {
        const list = allCockpitNewslist.edges.find(
          ({ node }) => node.cockpitId === id
        ).node;
        const newsItems = allCockpitNews.edges.map(({ node }) => {
          const [date, time] = node.cockpitCreated.split('T');
          const { publishDate, publishTime } = node;
          const newsPublish =
            (publishDate ? publishDate.value : date) +
            'T' +
            (publishTime ? publishTime.value + ':00.000' : time);
          return {
            id: node.cockpitId,
            created: new Date(newsPublish).toISOString(),
            title: node.title.value,
            image: node.image ? node.image.value.childImageSharp.fluid : null,
            subtitle: Boolean(node.subtitle) ? node.subtitle.value : undefined,
            source: Boolean(node.source) ? node.source.value : undefined,
            teaser: node.teaser.value.internal.content,
            text: Boolean(node.text)
              ? node.text.value.internal.content
              : undefined,
          };
        });
        
        newsItems.sort((a, b) => {
            if (a.created < b.created) {
                return 1;
            }
            
            if (a.created > b.created) {
                return -1;
            }
            
            return 0
        });

        return (
          <NewsList
            title={Boolean(list.title) ? list.title.value : undefined}
            items={
              Boolean(list.number)
                ? newsItems.slice(0, parseInt(list.number.value))
                : newsItems
            }
          />
        );
      }}
    />
  );
};

export { NewsCockpit };
