import React from 'react';

import style from './member.module.scss';

const MemberCard = ({ member }) => {
  const { image, name, rank } = member;

  return (
    <div className={style.memberCard}>
      <div
        className={`${style.memberImage}${
          !Boolean(image) ? ` ${style.noImage}` : ''
        }`}
      >
        {Boolean(image) && <img src={image} alt={name} />}
      </div>
      <div className={style.memberContent}>
        <div className={style.memberName}>{name}</div>
        <div className={style.memberRank}>{rank}</div>
      </div>
    </div>
  );
};

export { MemberCard };
