import React from 'react';

import style from './textimage.module.scss';

const Textimage = ({ children, image, imagePosition, imageAlt }) => (
  <div className={style.container}>
    <img
      src={image}
      className={`${style.image} ${style[imagePosition]}`}
      alt={imageAlt}
    />
    <div className={style.text}>{children}</div>
  </div>
);

export { Textimage };
